import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';
import theme from '@/styles/theme';

const { ToastContainer, toast: standaloneToast } = createStandaloneToast({
  theme,
});

const toast = (params: UseToastOptions) =>
  standaloneToast({
    isClosable: true,
    position: 'top-right',
    ...params,
  });

export { ToastContainer };

export default toast;
