export const APP_ROUTES = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  RESOURCES: '/resources',
  TEST_API: '/test_api',
  MY_PROFILE: '/my_profile',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  SUCCESS: '/confirmed',
  CONFIRMATION: '/verify-code',
  RESET_PASSWORD: '/reset-password',
};

export const API_ROUTES = {
  FILES: '/v1.0/files',
  DOWNLOAD_FILE: '/v1.0/files/access-url',
  CATEGORIES: '/v1.0/categories',
  SCHEME: {
    SRCI: '/v1.0/srci-test-api-scheme',
    SRCPI: '/v1.0/srcpi-test-api-scheme',
    DCF: '/v1.0/dcf-test-api-scheme',
  },
};

export const TEST_ROUTES = {
  SRCI: '/v1.0/srci-api-test-data-generation-requests',
  SRCPI: '/v1.0/srcpi-api-test-data-generation-requests',
};
