import { makeAutoObservable, runInAction } from 'mobx';
import axios, { isAxiosError } from 'axios';
import FileSaver from 'file-saver';
import { portalAPI } from '@/utils/api';
import { API_ROUTES } from '@/utils/routes';
import toast from '@/utils/toast';
import {
  AllFiles,
  CategoriesResponse,
  Category,
  FilesResponse,
  ReducedFile,
} from '@/types/resources';

export class ResourcesStore {
  constructor() {
    makeAutoObservable(this);
  }

  isLoading = false;

  allFiles: AllFiles = {};

  error = null;

  categories: Category[] = [];

  activeCategory = 'All';

  url = null;

  files: ReducedFile[] = [];

  downloading: string | null = null;

  getFiles = async (category = '') => {
    const {
      data: { files },
    } = await portalAPI<FilesResponse>(API_ROUTES.FILES, {
      params: {
        category,
      },
    });

    return files.map((f, index) => ({
      ...f,
      id: `${category}-${index}`,
      category,
    }));
  };

  getResources = async () => {
    try {
      if (!this.isLoading) {
        this.isLoading = true;

        if (this.categories.length === 0) {
          const { data: categories } = await portalAPI<CategoriesResponse>(
            API_ROUTES.CATEGORIES
          );

          const activeCategory = categories[0];

          const newFiles = await this.getFiles(activeCategory);

          this.allFiles = {
            ...this.allFiles,
            [activeCategory]: newFiles,
          };

          runInAction(() => {
            this.categories = categories;
            this.activeCategory = activeCategory;
            this.files = this.allFiles[activeCategory];
          });
        }
      }
    } catch (e) {
      if (isAxiosError(e)) {
        this.error = e?.response?.data?.message || 'Something went wrong';
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  onCategoryChange = async (index: number) => {
    try {
      const activeCategory = this.categories[index];
      if (activeCategory) {
        if (!this.allFiles[activeCategory]) {
          this.isLoading = true;

          const newFiles = await this.getFiles(activeCategory);

          this.allFiles = {
            ...this.allFiles,
            [activeCategory]: newFiles,
          };
        }

        runInAction(() => {
          this.files = this.allFiles[activeCategory];

          this.activeCategory = activeCategory;
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  downloadResource = async ({
    name,
    category,
    id,
  }: Pick<ReducedFile, 'name' | 'category' | 'id'>) => {
    this.downloading = id;

    try {
      const { data } = await portalAPI(API_ROUTES.DOWNLOAD_FILE, {
        params: {
          path: `${category}/${name}`,
        },
      });

      const { data: resourceData } = await axios(data.url, {
        responseType: 'blob',
      });

      const blob = new Blob([resourceData]);

      FileSaver.saveAs(blob, name);
    } catch (error) {
      toast({
        title: 'Failed to download the file.',
        description: 'Please try again later or contact your administrator.',
        status: 'error',
      });
    } finally {
      runInAction(() => {
        this.downloading = null;
      });
    }
  };
}

const resourcesStore = new ResourcesStore();

export default resourcesStore;
