import { uid } from 'uid';

import { SubjectDataObject } from '@/types/testApi';
import mockData from './mock-dpa-registration.json';

export function getDpaRegistration(xApiKey: string) {
  const res = { ...mockData } as {
    POST_DPA_REGISTRATION: SubjectDataObject & {
      body: {
        requestId: string;
        dpaBusinessData: {
          dpaPresentationName: string;
          dpaName: string;
        };
      };
    };
    GET_DPA_REGISTRATION: SubjectDataObject;
  };

  const UID_LENGTH = 32;

  const requestId = uid(UID_LENGTH);

  res.POST_DPA_REGISTRATION.body.requestId = requestId;
  res.POST_DPA_REGISTRATION.body.dpaBusinessData.dpaPresentationName = `DPA_Presentation_Name_${uid()}`;
  res.POST_DPA_REGISTRATION.body.dpaBusinessData.dpaName = `DPA_Name_${uid()}`;

  res.GET_DPA_REGISTRATION.queryParams = {
    requestId: [requestId],
  };

  if (xApiKey) {
    res.POST_DPA_REGISTRATION.headers['x-api-key'] = xApiKey;
    res.GET_DPA_REGISTRATION.headers['x-api-key'] = xApiKey;
  }

  return res;
}
