import { DefaultSeo as DefaultNextSeo } from 'next-seo';

const DefaultSeo = () => {
  return (
    <>
      <DefaultNextSeo
        defaultTitle="Developer Portal"
        titleTemplate="%s | Developer Portal"
        description="Discover® Secure Remote Commerce Developer Console"
      />
    </>
  );
};

export default DefaultSeo;
