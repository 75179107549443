import { makeAutoObservable, runInAction } from 'mobx';

import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';

import Router from 'next/router';
import { setPortalAccessToken } from '@/utils/api';
import { APP_ROUTES } from '@/utils/routes';
import {
  CognitoUserSession as SessionType,
  User,
  UserEditableKey,
} from '@/types/auth';

const DEFAULT_USER: User = {
  isAuthenticated: false,
  isLoading: false,
  email: '',
  given_name: '',
  family_name: '',
};

export class AuthStore {
  constructor() {
    makeAutoObservable(this);

    if (process.env.NODE_ENV !== 'test' && typeof window !== 'undefined') {
      this.autoLogin();
    }
  }

  signUpEmail = '';

  resetPasswordEmail = '';

  isInitializing = true;

  user: User = { ...DEFAULT_USER };

  handleLogout = async () => {
    try {
      this.user = { ...DEFAULT_USER };

      await Auth.signOut({ global: true });
    } catch (error) {
      console.error(error);
    }
  };

  autoLogin = async () => {
    try {
      runInAction(() => {
        this.user.isLoading = true;
      });

      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      const entryPathName = Router.asPath;

      if (user) {
        this.handleLoginSuccess(
          user.attributes,
          user.signInUserSession.accessToken.jwtToken,
          entryPathName
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.user.isLoading = false;
        this.isInitializing = false;
      });
    }
  };

  setSignUpEmail = (email: string) => {
    this.signUpEmail = email;
  };

  setResetPasswordEmail = (email: string) => {
    this.resetPasswordEmail = email;
  };

  handleLoginSuccess = async (
    userAttributes: Omit<User, 'isAuthenticated'>,
    accessToken: string,
    redirectPath?: string
  ) => {
    try {
      setPortalAccessToken(accessToken);

      runInAction(() => {
        this.user = {
          isAuthenticated: true,
          ...userAttributes,
        };
      });

      Router.push(redirectPath || APP_ROUTES.DASHBOARD);
    } catch (error) {
      console.error(error);
    }
  };

  updateUserAttribute = (name: UserEditableKey, value: string) => {
    this.user[name] = value;
  };

  refreshSession = async (
    currentUser: CognitoUser,
    currentSession: CognitoUserSession
  ) => {
    return new Promise<string>((resolve, reject) => {
      currentUser.refreshSession(
        currentSession.getRefreshToken(),
        (err: Error, session: SessionType) => {
          if (err) {
            reject(err);
            return;
          }

          const accessToken = session.accessToken.jwtToken;

          resolve(accessToken);
        }
      );
    });
  };

  refreshToken = async () => {
    try {
      const currentUser: CognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      const accessToken = await this.refreshSession(
        currentUser,
        currentSession
      );

      if (accessToken) {
        setPortalAccessToken(accessToken);
      }

      return accessToken;
    } catch (error) {
      console.error('Error refreshing tokens', error);
    }
  };
}

const authStore = new AuthStore();

export default authStore;
