import axios, { AxiosError } from 'axios';
import authStore from '@/stores/authStore';
import {
  generateIdempotencyKey,
  setApiVersionHeaders,
  shouldRetry,
} from './apiUtils';

const NUMBER_OF_RETRIES = 1;

interface ErrorResponse {
  reason: string;
  message: string;
  errorDetail?: { message: string }[];
}

export const portalAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

export const setPortalAccessToken = (token: string) => {
  portalAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export async function onRejected(error: AxiosError<ErrorResponse>) {
  const { response, config } = error;

  if (!response) {
    return Promise.reject(error);
  }

  const isUnauthorized = response?.status === 401;

  if (!config || !shouldRetry(response?.status)) {
    return Promise.reject(error);
  }

  if (config.retryCount && config.retryCount > 0) {
    config.retryCount = config.retryCount - 1;

    if (isUnauthorized) {
      const accessToken = await authStore.refreshToken();

      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }

    try {
      return await portalAPI.request(config);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  if (isUnauthorized) {
    authStore.handleLogout();
  }

  return Promise.reject(error);
}

portalAPI.interceptors.response.use((res) => res, onRejected);

portalAPI.interceptors.request.use(function (config) {
  const headers = setApiVersionHeaders(config);

  // Add the Idempotency Key header if the method is not GET or PUT.
  if (config.method && config.method !== 'GET' && config.method !== 'PUT') {
    const idempotencyKey = generateIdempotencyKey();
    headers['Idempotency-Key'] = idempotencyKey;
  }

  const retryCount =
    config?.retryCount === undefined ? NUMBER_OF_RETRIES : config.retryCount;

  return {
    ...config,
    headers,
    retryCount,
  };
});
