import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { noop, uniqueId } from 'lodash-es';
import { getDpaRegistration } from '@/components/testAPIs/assets/getDpaRegistration';
import { portalAPI } from '@/utils/api';
import { API_ROUTES, TEST_ROUTES } from '@/utils/routes';
import {
  AccessTokenFormValues,
  MappedPath,
  Parameter,
  RequestType,
  ResponseType,
  Spec,
  SubjectData,
  SubjectDataObject,
  SubjectKey,
} from '@/types/testApi';

const SERVERS = {
  servers: [
    {
      url: process.env.NEXT_PUBLIC_TEST_API_BASE_URL,
    },
  ],
};

const DEFAULT_DATA: SubjectData = {
  data: null,
  isLoading: false,
  isFetching: false,
  fetchError: false,
  error: false,
  accessToken: {
    data: null,
    isLoading: false,
    error: false,
  },
  keys: {
    data: null,
    isLoading: false,
    error: false,
  },
  schema: {
    data: '',
    isLoading: false,
    error: false,
  },
  isPreparingAll: false,
  helpers: {},
  spec: null,
  defaultSpec: null,
};

export class TestApiStore {
  constructor() {
    makeAutoObservable(this);
  }

  SRCI: SubjectData = {
    ...DEFAULT_DATA,
  };

  SRCPI: SubjectData = {
    ...DEFAULT_DATA,
  };

  getSpecs = async (key: SubjectKey) => {
    try {
      this[key].isFetching = true;

      const { data } = await portalAPI.get(API_ROUTES.SCHEME[key]);

      this[key].spec = { ...data, ...SERVERS };
      this[key].defaultSpec = { ...data, ...SERVERS };
    } catch (error) {
      this[key].fetchError = true;
    } finally {
      this[key].isFetching = false;
    }
  };

  propagateData = async (key: SubjectKey) => {
    runInAction(() => {
      this[key].isPreparingAll = true;
      this[key].helpers = {};
    });

    const generatedData = this[key].data;

    if (generatedData) {
      if (generatedData.GET_KEYS) {
        await this.getKeys(key);
      }

      await this.fillAllData(key);
    }

    runInAction(async () => {
      this[key].isPreparingAll = false;
    });
  };

  resetData = (key: SubjectKey) => {
    this[key] = {
      ...DEFAULT_DATA,
    };
    if (this[key].defaultSpec) {
      this[key].spec = this[key].defaultSpec;
    }
  };

  generateData = async (
    key: SubjectKey,
    param: { name: string; value: string } | null,
    onSuccess = noop
  ) => {
    try {
      runInAction(() => {
        this[key].isLoading = true;
        this[key].error = false;
      });

      const payload = {
        version: 'v1.1',
        ...(param && {
          [param.name]: param.value,
        }),
      };

      const { data } = await portalAPI.post(TEST_ROUTES[key], payload);
      let testData = data.testData || {};

      if (key === 'SRCI') {
        testData = {
          ...testData,
          ...getDpaRegistration(testData.CONFIRMATION?.headers['x-api-key']),
        };
      }

      runInAction(() => {
        this[key].data = testData;
      });

      onSuccess();
    } catch (error) {
      this[key].error = true;
    } finally {
      runInAction(() => {
        this[key].isLoading = false;
      });
    }
  };

  getAccessToken = async (
    key: SubjectKey,
    data: AccessTokenFormValues,
    onSuccess = noop
  ) => {
    try {
      runInAction(() => {
        this[key].accessToken.isLoading = true;
        this[key].accessToken.error = false;
      });

      const { baseUrl, targetPath, basicAuth, grantType } = data || {};

      const getAccessToken = this[key].data?.GET_ACCESS_TOKEN;

      if (!getAccessToken) return;

      const { headers = {}, queryParams = {} } = getAccessToken;

      const {
        data: { access_token },
      } = await axios.post(`${baseUrl}${targetPath}`, null, {
        headers: {
          ...headers,
          Authorization: basicAuth,
        },
        params: {
          ...queryParams,
          grant_type: grantType,
        },
      });

      runInAction(() => {
        this[key].accessToken.data = access_token;
      });

      onSuccess();
    } catch (error) {
      this[key].accessToken.error = true;
    } finally {
      runInAction(() => {
        this[key].accessToken.isLoading = false;
      });
    }
  };

  setToken = (key: SubjectKey, data: string) => {
    this[key].accessToken.data = data;
  };

  getKeys = async (key: SubjectKey) => {
    try {
      runInAction(() => {
        this[key].keys.isLoading = true;
        this[key].keys.error = false;
      });

      const getKeys = this[key].data?.GET_KEYS;

      if (!getKeys) return;

      const { baseUrl, targetPath, headers = {} } = getKeys;

      const {
        data: { keys },
      } = await axios.get(`${baseUrl}${targetPath}`, {
        headers: {
          ...headers,
        },
      });

      runInAction(() => {
        this[key].keys.data = keys;
      });
    } catch (error) {
      this[key].keys.error = true;
    } finally {
      runInAction(() => {
        this[key].keys.isLoading = false;
      });
    }
  };

  handleParameters = (
    param: Parameter,
    key: SubjectKey,
    DATA: SubjectDataObject,
    defaultComponents: Spec['components']
  ) => {
    let newParam = { ...param };
    if (param.$ref) {
      const paramKey = param.$ref.split('/').pop() as string;
      newParam = { ...defaultComponents.parameters[paramKey] };
    }
    if (newParam.name) {
      if (DATA.headers?.[newParam.name]) {
        newParam.default = DATA.headers[newParam.name][0];
      }
      if (DATA.queryParams?.[newParam.name]) {
        newParam.default = DATA.queryParams[newParam.name][0];
      }
    }
    if (
      this[key].accessToken.data &&
      newParam.name === 'Authorization' &&
      newParam.default
    ) {
      newParam.default = newParam.default.replace(
        // eslint-disable-next-line no-template-curly-in-string
        '${accessToken}',
        this[key].accessToken.data as string
      );
    }

    return newParam;
  };

  fillPathsData = async (key: SubjectKey, paths: MappedPath[] = []) => {
    const oldSpec = this[key].spec;

    if (!oldSpec) return;

    const newSpec = { ...oldSpec };

    const defaultComponents = newSpec.components;

    if (!defaultComponents) return;

    paths.forEach(({ path, method, dataKey }) => {
      const pathData = newSpec.paths?.[path]?.[method];

      if (pathData) {
        const parameters = [...pathData.parameters];

        const DATA = this[key]?.data?.[dataKey];

        if (!DATA) return;

        const newParameters = parameters.map((param) =>
          this.handleParameters(param, key, DATA, defaultComponents)
        );

        pathData.parameters = newParameters;

        if (DATA.body && pathData.requestBody.content['application/json']) {
          let bodySchema =
            pathData.requestBody.content['application/json'].schema;

          if (bodySchema.$ref) {
            const schemaKey = bodySchema.$ref.split('/').pop() as string;
            bodySchema = {
              ...defaultComponents.schemas[schemaKey],
              example: DATA.body,
            };
          }

          pathData.requestBody.content['application/json'].schema = bodySchema;
        }
      }
    });

    this[key].spec = newSpec;
  };

  fillAllData = async (key: SubjectKey) => {
    try {
      runInAction(() => {
        this[key].schema.isLoading = false;
        this[key].schema.error = false;
      });

      const data = this[key].data;

      if (!data) return;

      const paths = Object.entries(data).map(
        ([dataKey, { targetPath, pathParams, method }]) => {
          let newPath = targetPath;

          if (pathParams?.version) {
            newPath = newPath.replace(`{version}`, pathParams?.version);
          }

          return {
            dataKey,
            path: newPath,
            method: method.toLowerCase(),
          };
        }
      );

      await this.fillPathsData(key, paths);
      runInAction(() => {
        this[key].schema.data = uniqueId('schema-');
      });
    } catch (error) {
      this[key].schema.error = true;
    } finally {
      runInAction(() => {
        this[key].schema.isLoading = false;
      });
    }
  };

  handleRequest = (req: RequestType, reqPath: string, dataKey: SubjectKey) => {
    const { data, accessToken } = this[dataKey];

    if (!data || !accessToken.data) return req;

    const requestData = Object.values(data).find(
      ({ targetPath, pathParams, method }) => {
        let newPath = targetPath;

        if (pathParams?.version) {
          newPath = newPath.replace(`{version}`, pathParams?.version);
        }

        return newPath === reqPath && method === req.method;
      }
    );

    const xApiKey = requestData?.headers?.['x-api-key'];

    const authorization = requestData?.headers?.Authorization[0].replace(
      // eslint-disable-next-line no-template-curly-in-string
      '${accessToken}',
      accessToken.data as string
    );

    return {
      ...req,
      headers: {
        ...req.headers,
        Authorization: authorization,
        ...(xApiKey && {
          'x-api-key': xApiKey,
        }),
      },
    };
  };

  handleResponse = (
    res: ResponseType,
    reqPath: string,
    dataKey: SubjectKey
  ) => {
    if (dataKey === 'SRCPI' && reqPath === '/srcpi/v1.1/cards') {
      const cardId = res.body?.maskedCard?.srcPaymentCardId;

      if (cardId) {
        this[dataKey].helpers.cardId = cardId;
      }
    }

    return res;
  };
}

const testApiStore = new TestApiStore();

export default testApiStore;
