import React from 'react';
import { observer } from 'mobx-react';
import { Center, Spinner } from '@chakra-ui/react';

const Loader = () => {
  return (
    <Center
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      background="rgba(250,250,250,0.9)"
      zIndex={1000}
    >
      <Spinner
        thickness="4px"
        speed="1s"
        emptyColor="gray.300"
        color="discoverOrange"
        size="xl"
      />
    </Center>
  );
};

export default observer(Loader);
