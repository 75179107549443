import { InternalAxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';

const apiVersion = process.env.NEXT_PUBLIC_API_VERSION ?? '1';

const apiVersionString = `version=${apiVersion}`;

export const applyApiVersion = (header: string) =>
  header
    .split(',')
    .map((h) => `${h};${apiVersionString}`)
    .join(',');

const contentType = 'Content-Type';

export const setApiVersionHeaders = (config: InternalAxiosRequestConfig) => {
  const headers = config.headers;

  if (headers.Accept) {
    headers.Accept = applyApiVersion(headers.Accept as string);
  }

  if (headers[contentType]) {
    headers[contentType] = applyApiVersion(headers[contentType] as string);
  } else {
    headers[contentType] = `application/json;${apiVersionString}`;
  }

  return headers;
};

// Add a function to generate an Idempotency Key
export function generateIdempotencyKey() {
  return uuidv4();
}

// Add a function to perform retries based on the response status code.
export function shouldRetry(status: number) {
  return (status >= 500 && status <= 599) || status === 429 || status === 401;
}
