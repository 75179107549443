import { createContext, useContext } from 'react';

import authStore from '@/stores/authStore';
import resourcesStore from '@/stores/resourcesStore';
import testApiStore from '@/stores/testApiStore';

export const storesContext = createContext({
  authStore,
  resourcesStore,
  testApiStore,
});

export const useStores = () => useContext(storesContext);
