import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'full',
    _focus: {
      transition: 'box-shadow 0.15s',
    },
  },
  variants: {
    link: {
      padding: '2px',
    },
  },
};
