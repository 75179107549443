/* eslint-disable @typescript-eslint/no-explicit-any */
import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import Head from 'next/head';
import { Amplify } from 'aws-amplify';
import { NextPage } from 'next';
import customTheme from '@/styles/theme';
import DefaultSeo from '@/components/app/DefaultSeo';

import { awsConfig } from '@/utils/awsConfig';
import AuthGuard from '@/components/app/AuthGuard';
import { ToastContainer } from '@/utils/toast';

import getAppVersion from '@/utils/getAppVersion';

Amplify.configure(awsConfig);

export type NextApplicationPage<P = unknown, IP = P> = NextPage<P, IP> & {
  requireAuth?: boolean;
};

interface AppPropsWithComponent extends AppProps {
  Component: NextApplicationPage;
}

export default function App({ Component, pageProps }: AppPropsWithComponent) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
        <meta name="version" content={getAppVersion()} />
      </Head>
      <DefaultSeo />
      <ChakraProvider theme={customTheme}>
        {Component.requireAuth ? (
          <AuthGuard>
            <Component {...pageProps} />
          </AuthGuard>
        ) : (
          <Component {...pageProps} />
        )}
        <ToastContainer />
      </ChakraProvider>
    </>
  );
}
