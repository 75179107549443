const domain = process.env.NEXT_PUBLIC_AWS_DOMAIN;
const region = process.env.NEXT_PUBLIC_AWS_REGION;
const appClientId = process.env.NEXT_PUBLIC_AWS_APP_CLIENT_ID;
const userPoolId = process.env.NEXT_PUBLIC_AWS_USER_POOL_ID;

const awsConfig = {
  Auth: {
    region,
    userPoolId,
    userPoolWebClientId: appClientId,
  },
};

export { domain, region, appClientId, userPoolId, awsConfig };
