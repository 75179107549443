import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/hooks/useStores';
import { APP_ROUTES } from '@/utils/routes';
import Loader from '@/components/common/Loader';

const AuthGuard = ({ children }: { children: React.JSX.Element }) => {
  const {
    authStore: { user, isInitializing },
  } = useStores();
  const router = useRouter();

  const { isAuthenticated, isLoading } = user;

  useEffect(() => {
    //auth is initialized and there is no user
    if (!isInitializing && !isAuthenticated) {
      router.push(APP_ROUTES.HOME);
    }
  }, [isInitializing, router, isAuthenticated]);

  /* show loading indicator while the auth provider is still initializing */
  if (isInitializing || isLoading) {
    return <Loader />;
  }

  // if auth initialized with a valid user show protected page
  if (!isInitializing && isAuthenticated) {
    return <>{children}</>;
  }

  /* otherwise don't return anything, will do a redirect from useEffect */
  return null;
};

export default observer(AuthGuard);
