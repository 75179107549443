import packageInfo from 'package.json';

const { version: VERSION } = packageInfo;
const COMMIT_HASH = process.env.COMMIT_HASH;

export default function getAppVersion() {
  const commitHashString = COMMIT_HASH ? ` ${COMMIT_HASH}` : '';

  return `${VERSION}${commitHashString}`;
}
